import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import Fade from 'react-reveal/Fade';

const lightTheme = {
  background: '#f1f2f6',
  color: '#1e272e',
  linkColor: '#ff4757',
  hoverBackground: '#f1f2f6',
};

const darkTheme = {
  background: '#101519',
  color: '#f1f2f6',
  linkColor: '#ff4757',
  hoverBackground: '#2f3542',
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    transition: background-color 0.3s ease, color 0.3s ease;
  }
`;

const AppContainer = styled.div`
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 40px;
`;

const Name = styled.h1`
  font-size: 3em;
  color: ${({ theme }) => theme.color};
  margin: 0;
`;

const SubTitle = styled.p`
  font-size: 1.3em;
  color: ${({ theme }) => theme.color};
`;

const Section = styled.section`
  margin-bottom: 50px;
`;

const SectionTitle = styled.h2`
  font-size: 2em;
  color: ${({ theme }) => theme.color};
  border-bottom: 3px solid ${({ theme }) => theme.linkColor};
  padding-bottom: 10px;
`;

const Text = styled.p`
  font-size: 1.1em;
  color: ${({ theme }) => theme.color};
  line-height: 1.6;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  margin-bottom: 15px;
  font-size: 1.1em;
  color: ${({ theme }) => theme.color};
  padding: 5px;
  transition: transform 0.2s, background 0.3s ease;

  &:hover {
    transform: scale(1.02);
    background: ${({ theme }) => theme.hoverBackground};
  }
`;

const ContactLink = styled.a`
  color: ${({ theme }) => theme.linkColor};
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const ThemeToggleButton = styled.button`
  position: fixed;
  top: 20px;
  right: 15px; /* Adjusted to be fully visible */
  background: ${({ theme }) => theme.linkColor};
  color: ${({ theme }) => theme.background};
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 1em;
  border-radius: 50%;
  z-index: 1000; /* Ensure it's on top */
  transition: right 0.3s ease-in-out;

  @media (max-width: 768px) {
    padding: 5px;
    font-size: 0.8em;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

function App() {
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyle />
      <AppContainer>
        <ThemeToggleButton onClick={toggleTheme}>
          {theme === 'light' ? '🌙' : '☀️'}
        </ThemeToggleButton>
        <Header>
          <Fade top>
            <Name>Renato Liski</Name>
            <SubTitle>Programming | Electrical Engineering | Robotics | Computer Vision</SubTitle>
          </Fade>
        </Header>

        <Fade bottom>
          <Section>
            <SectionTitle>About Me</SectionTitle>
            <List>
              <ListItem>
                I am a dedicated student pursuing a Bachelor of Engineering in Electrical and Automation Engineering, with a bigger lean on the automation part. My studies involve not only mastering automation systems but also engaging in group work and learning to collaborate effectively as part of a group.
              </ListItem>
              <ListItem>
              Even outside of my studies, I love coding and exploring artificial intelligence, especially its different applications and concepts. Usually when I do get some free time, I unwind by playing video games.
              </ListItem>
            </List>
          </Section>
        </Fade>
        <Fade bottom>
          <Section>
            <SectionTitle>Education</SectionTitle>
            <List>
              <ListItem>Mechanical Engineering - Satakunta University of Applied Sciences (2021-2022)</ListItem>
              <ListItem>Electrical Engineering and Automation, RoboAI Academy - Satakunta University of Applied Sciences (2022-2025)</ListItem>
            </List>
          </Section>
        </Fade>
        <Fade bottom>
          <Section>
            <SectionTitle>Skills</SectionTitle>
            <List>
              <ListItem>Computer Vision: OpenCV, MVTEC Halcon</ListItem>
              <ListItem>Programming Languages: C, C++, Python, JavaScript, Ruby</ListItem>
              <ListItem>Microcontrollers: Arduino, Raspberry Pi</ListItem>
              <ListItem>Robotics: Autonomous Systems, Sensors Integration, Logic Programming</ListItem>
              <ListItem>Electrical Engineering: Circuit Design, PCB Layout</ListItem>
            </List>
          </Section>
        </Fade>

        <Fade bottom>
          <Section>
            <SectionTitle>Projects</SectionTitle>
            <List>
              <ListItem>Automated Beer Can Weighing System - Designed and programmed an affordable solution for a brewery to weigh cans of beer, display them on an OLED screen and automatically discard the ones below a specified weight, using an Arduino and pneumatics.</ListItem>
              <ListItem>Bolt Machine Vision System - Designed and taught a machine vision solution to detect bolts and identify missing bolts or empty bolt holes in steel objects on a factory line, ensuring quality control.</ListItem>
            </List>
          </Section>
        </Fade>

        <Fade bottom>
          <Section>
            <SectionTitle>Contact me</SectionTitle>
            <Text>Email: renato.liski@gmail.com</Text>
            <Text>GitHub: <ContactLink href="https://github.com/renskursa" target="_blank">https://github.com/renskursa</ContactLink></Text>
          </Section>
        </Fade>
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;